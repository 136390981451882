<template>
	<div class="storePerformance">
		<div class="flexRow" style="justify-content: space-between;">
			<el-tabs v-model="listState" @tab-click="filterFun" style="flex:1">
				<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
			</el-tabs>
			<!-- <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnHW9y3oGSaogp3lB3JCbldc" 
			 :underline="false" target="_blank" type="primary" class="link">查看业绩统计说明></el-link> -->
		</div>

		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="width: 75px;display: inline-block;text-align: right;">关键字: </label>
				<el-input v-model="searchKey" @keyup.enter.native="doorListFilter"  placeholder="门店名称、编码" style="width: 250px;" clearable></el-input>
			</div>
			<div class="filter-item">
				<label class="label">门店标签: </label>
				<el-select v-model="ShopTagId" placeholder="请选择" filterable clearable style="width: 300px;margin-right: 10px;">
					<el-option :value="null" label="全部"></el-option>
					<el-option v-for="(item,index) in LabelList" :key="index" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">门店状态: </label>
				<el-select v-model="doorState" placeholder="请选择" filterable clearable style="width: 150px;">
					<el-option :value="null" label="全部"></el-option>
					<el-option v-for="(item,index) in doorStateList" :key="index" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>

		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">{{listState==0?'支付时间:':'结算时间:'}} </label>
				<!-- :picker-options="pickerOptions" -->
				<el-date-picker type="datetime" placeholder="选择日期"  v-model="starTime" style="width: 250px;"
				 value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期"  v-model="endTime" style="width: 250px;"
				 value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="doorListFilter">查询</el-button>
				<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
			</div>
		</div>
		<div style="background:#F5F5F5;padding: 15px;margin-bottom:10px;font-size: 15px;">
			<span>订单毛利总额：{{GrossProfitMoney}}</span> <span style="margin-left: 25px;">待发货订单：{{PendingOrderCount}}（发出包裹数量：{{PendingOrderExpressCount}}）</span> <span style="margin:0 25px">已发货订单：{{SendOrderCount}}（发出包裹数量：{{SendOrderExpressCount}}）</span> <span>全部退款订单：{{RefundOrderCount}}（发出包裹数量：{{RefundOrderExpressCount}}）</span>
		</div>
		<!-- 支付业绩 -->
		<!-- 表格 -->
		<el-table :data="tableData" style="width: 100%" v-loading="loading">
			<el-table-column prop="ShopName" label="门店" width="150">
				<template slot-scope="scope">
					<div>{{scope.row.ShopName}}</div>
					<div style="color:rgb(113, 111, 111);">{{scope.row.ShopNo}}</div>
				</template>
			</el-table-column>
			<el-table-column label="快递发货订单">
				<el-table-column prop="ExpressOrderCount" label="订单数量"></el-table-column>
				<el-table-column prop="ExpressOrderMoney" label="订单金额"></el-table-column>
				<el-table-column prop="ExpressOrderGrossProfitMoney" label="订单毛利"></el-table-column>
			</el-table-column>
			<el-table-column label="到店自提订单">
				<el-table-column prop="PickUpOrderCount" label="订单数量"></el-table-column>
				<el-table-column prop="PickUpOrderMoney" label="订单金额"></el-table-column>
				<el-table-column prop="PickUpOrderGrossProfitMoney" label="订单毛利"></el-table-column>
			</el-table-column>
			<el-table-column label="退款单">
				<el-table-column prop="RefundMoney" label="退款成功金额"></el-table-column>
				<el-table-column prop="RefundGrossProfitMoney" label="退款单毛利"></el-table-column>
			</el-table-column>
			<el-table-column label="操作" width="190">
				<template slot-scope="scope">
					<el-button type="text" @click="storeDetail(scope.row)">业绩详情</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div style="text-align: right;">
			<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="Total">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		shopPerformanceindex,
		shopPerformanceInt
	} from '@/api/goods'
	import{
		ShoperformanceInfo
	} from '@/api/TurnTomySelf'
	export default {
		data() {
			return {
				PendingOrderCount:0,
				PendingOrderExpressCount:0,
				GrossProfitMoney :0,
				SendOrderCount :0,
				SendOrderExpressCount :0,
				RefundOrderCount :0,
				RefundOrderExpressCount :0,
				listStateList: [{
						id: 0,
						value: '支付业绩'
					},
					{
						id: 1,
						value: '结算业绩'
					}
				],
				listState: '0',
				searchKey: '',
				ShopTagId: '',
				LabelList: [],
				pickerOptions: {
					disabledDate(time) {
						let curDate = (new Date()).getTime();
						let three = 30 * 24 * 3600 * 1000;
						let threeMonths = curDate - three;
						return time.getTime() > Date.now() || time.getTime() < threeMonths;
					}
				},
				starTime: '',
				endTime: '',
				exportUrl: config.EXPORT_URL,
				tableData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				doorState:true,
				doorStateList:[
					{id:true,value:'正常营业'},
					{id:false,value:'停用'}
				]

			}
		},
		beforeMount() {
			//顶部统计
			
			this.getNowDay()
			this.getStoreLabel()
			this.getList()
			
			this.getShoperformanceInfo()
		},
		methods: {
			//获取顶部信息
			async getShoperformanceInfo(){
				try{
					this.loading = true
					let data = {
						Keywords : this.searchKey,
						ShopTagId : this.ShopTagId,
						PerformanceType : this.listState,
						StartTime : this.starTime,
						EndTime : this.endTime,
						IsOpen : this.doorState
					}
					let result = await ShoperformanceInfo(data)
					if(result.IsSuccess){
						this.GrossProfitMoney  = result.Result.GrossProfitMoney
						this.SendOrderCount   = result.Result.SendOrderCount 
						this.SendOrderExpressCount  = result.Result.SendOrderExpressCount 
						this.RefundOrderCount  =  result.Result.RefundOrderCount 
						this.RefundOrderExpressCount  =  result.Result.RefundOrderExpressCount 
						this.PendingOrderCount =  result.Result.PendingOrderCount 
						this.PendingOrderExpressCount =  result.Result.PendingOrderExpressCount 
					}
				}catch(err){
					
				}finally{
					this.loading = false
				}
			},
			// 获取今日时间
			getNowDay() {
				let date = new Date();
				let year = date.getFullYear();
				let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
				let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());
				this.starTime = year + '-' + month + '-' + day + ' ' + '00:00:00';
				this.endTime = year + '-' + month + '-' + day + ' ' + '23:59:59';
			},
			// 支付业绩列表,结算业绩
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey,
						IsOpen :this.doorState,
						ShopTagId: this.ShopTagId,
						PerformanceType: this.listState, //0支付业绩，1结算业绩
						StartTime: this.starTime,
						EndTime: this.endTime,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await shopPerformanceindex(data)
					this.Total = result.Result.Total;
					this.tableData = result.Result.Results || [];

				} catch (e) {
					//TODO handle the exception
				} finally {
					this.loading = false
				}

			},
			//顶部tab切换事件
			filterFun(tab, event) {
				this.searchKey = ''
				this.ShopTagId = ''
				this.doorState=true
				this.currentPage = 1
				this.pageSize = 20
				this.getNowDay()
				this.getList()
				this.getShoperformanceInfo()
			},
			async getStoreLabel() {
				try {
					let result = await shopPerformanceInt({})
					this.LabelList = result.Result.ShopTagSelectList
				} catch (err) {

				}
			},
			// 搜索
			doorListFilter() {
				if (!this.starTime || (!this.endTime)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '最多可查询30天内的订单，请重新选择时间!'
					});
					return
				}

				this.currentPage = 1
				this.getList();
				this.getShoperformanceInfo()
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
				this.getShoperformanceInfo()
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
				this.getShoperformanceInfo()
			},
			// 导出
			async exportFun() {
				try {
					this.loading = true;
					if (!this.starTime || (!this.endTime)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '最多可查询30天内的订单，请重新选择时间!'
						});
						return
					}
					let url = this.exportUrl + '/pc/shop-performance/index-export?' +
						'&Keywords=' + (this.searchKey ? encodeURIComponent(this.searchKey) : '') +
						'&IsOpen=' +(this.doorState==null?'':this.doorState)+
						'&ShopTagId=' + (this.ShopTagId ? this.ShopTagId : '') +
						'&StartTime=' + (this.starTime ? this.starTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '') +
						'&PerformanceType=' + this.listState
						console.log(url,'导出的地址')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},
			// 业绩详情
			storeDetail(record) {
				if (this.listState == 0) {
					this.$router.push({
						path: '/door/payDetails',
						query: {
							Id: record.ShopId ? record.ShopId : 0,
							starTime: this.starTime,
							endTime: this.endTime
						}
					});
				} else {
					this.$router.push({
						path: '/door/settleDetails',
						query: {
							Id: record.ShopId ? record.ShopId : 0,
							starTime: this.starTime,
							endTime: this.endTime
						}
					});
				}
			},

		}
	}
</script>

<style lang="less" scoped>
	.storePerformance {
		background: #fff;
		padding: 15px;
	}
	.flexRow{
		display: flex;
		align-items: center;
		// justify-content: space-between;
		position: relative;
		.link{
			position: absolute;
			right: 0px;
			top: 15px;
		}
	}
</style>
